/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { css } from "@emotion/react"
import { Typography, Box, Button, TextField, Link, InputAdornment, Avatar, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Refresh, Eye, EyeOff, } from 'react-ionicons'
import { passwordRuleValidator, checkPasswordsMatch} from '../common/passwordUtils'
import { emailValidator } from '../common/emailUtils'
import { avatarRandomizer } from '../common/avatarUtils'

const generateField = css`
  display: flex;
  gap: 10px;
  align-items: stretch;
`

const showPasswordStyle = css`
  line-height: 0;
  gap: 5px;
  cursor: pointer; 
`

const chooseAvatar = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`

const randomizeButton = css`
  border: 1px solid rgba(224, 21, 122, 0.5);
  svg: {
    fill: #E0157A;
  }
`

const SignUpPage = ({ endpoint, pageLayout, formLayout }) => {

  useEffect(() => { 
    document.title = "Sign Up - ShopSharer"
    if (endpoint) {
      handleUsernameRandomizer()
    }
  }, [endpoint])

  let navigate = useNavigate()

  const handleClickGoBack = () => {
    navigate(-1)
  }

  const [selectedAvatar, setSelectedAvatar] = useState(avatarRandomizer())
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // const [userCredentials, setUserCredentials] = useState({
  //   username: 'TestUserName123',
  //   email: '',
  //   password: '',
  //   confirmPassword: ''
  // })

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [emailError, setEmailError] = useState('')
  const [passwordErrors, setPasswordErrors] = useState([])
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  
  const handlePasswordBlur = () => {
    const errors = passwordRuleValidator(password)

    setPasswordErrors(errors)
  }

  const handleConfirmPasswordBlur = () => {
    setConfirmPasswordError(checkPasswordsMatch(password, confirmPassword))
  }

  const handleEmailBlur = () => {
    setEmailError(emailValidator(email))
  }

  const handleUsernameRandomizer = async () => {
    try {
      const response = await axios.get(`${endpoint}/users/generate-username`)
      setUsername(response.data.message)
    } catch (error) {
      console.log("Errors:", error)
    }
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    const errors = emailError || passwordErrors > 0 || confirmPasswordError

    let body = {
      "userAvatar": selectedAvatar,
      "username": username,
      "email": email,
      "password": password
    }

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      mode: "cors",
      credentials: "same-origin"
    }

    if (!errors) {
      try {
        const response = await axios.post(`${endpoint}/users/signup`, body, options)
        window.location.href = '/login'
        console.log("Body", body)
        console.log("response data", response.data)
      } catch (error) {
        console.error("Errors", error);
      }
    }
  }

  return (
    <Box css={pageLayout}>
      <Button 
        onClick={handleClickGoBack} 
        style={{alignSelf: "flex-start"}}
      >
        &lang; Back
      </Button>
      <Typography variant="h1" style={{textAlign: "center"}}>Sign Up</Typography>
      <form css={formLayout} onSubmit={handleFormSubmit}>
        <Box css={chooseAvatar}>
          <Avatar 
            src={`/avatars/${selectedAvatar}`}
            alt="Avatar"
            css={css`height: 100px; width: 100px; background-color: #fde9f3`}
          />
          {/* <Button onClick={()=> setSelectedAvatar(avatarRandomizer())} variant="outlined">Randomize Avatar</Button> */}
          <Box css={css`display: flex; align-items: center; gap: 10px; flex-direction: column;`}>
            <IconButton css={randomizeButton} color="primary" onClick={()=> setSelectedAvatar(avatarRandomizer())}>
              <Refresh />
            </IconButton>
            <Typography css={css`font-size: 12px;`}>Random Avatar</Typography>
          </Box>
        </Box>
        <Box css={generateField}>
          <TextField 
            label="Username"
            value={username}
            // onChange={(e) => setUsername(e.target.value)}
            disabled
            autoComplete='new-username'
            InputProps={{
              readOnly: true,
            }}
            style={{flexGrow: 1}}
          />
          {/* <Button variant="outlined">Generate</Button> */}
          <Box css={css`display: flex; align-items: center; gap: 10px;`}>
            <IconButton css={randomizeButton} color="primary" onClick={handleUsernameRandomizer}>
              <Refresh />
            </IconButton>
            <Typography css={css`font-size: 12px;`}>Random Username</Typography>
          </Box>
        </Box>
        <TextField 
          label="Email"
          type="email"
          autoComplete='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleEmailBlur}
          error={emailError.length > 0}
          helperText={emailError}
        />
        <TextField 
          label="Password"
          value={password}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={handlePasswordBlur}
          autoComplete='new-password'
          error={passwordErrors.length > 0}
          helperText={passwordErrors.map((error) => (
            <React.Fragment key={error}>
              {error}
              <br />
            </React.Fragment>
          ))}
          InputProps={{
            endAdornment:
              <InputAdornment css={showPasswordStyle} onClick={()=> setShowPassword(!showPassword)} position="end">
                <Typography>{showPassword ? "Hide" : "Show"}</Typography>
                {showPassword ? <EyeOff color="#565656" /> : <Eye color="#565656" />}
              </InputAdornment>
          }}
        />
        <TextField 
          label="Confirm Password"
          value={confirmPassword}
          type={showConfirmPassword ? 'text' : 'password'}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={handleConfirmPasswordBlur}
          error={confirmPasswordError.length > 0}
          helperText={confirmPasswordError}
          autoComplete='new-password'
          InputProps={{
            endAdornment:
              <InputAdornment css={showPasswordStyle} onClick={()=> setShowConfirmPassword(!showConfirmPassword)} position="end">
                <Typography>{showConfirmPassword ? "Hide" : "Show"}</Typography>
                {showConfirmPassword ? <EyeOff color="#565656" /> : <Eye color="#565656" />}
              </InputAdornment>
          }}
        />
        <Button type="submit" variant="contained" disableElevation>
          Create Account
        </Button>
      </form>
      <Typography style={{textAlign: "center"}}>
        Already have an account? <Link underline="hover" href="/login" style={{fontWeight: 500}}>Log In &#10217;</Link>
      </Typography>
    </Box>
  )
}

export default SignUpPage