/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { css } from "@emotion/react"
import { Typography, Button, Box, InputAdornment, IconButton, Link, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Eye, EyeOff } from 'react-ionicons'
import { emailValidator } from '../common/emailUtils'
import { ReactComponent as Logo } from '../assets/shopsharer-logo.svg'

const formAction = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`
const showPasswordStyle = css`
  gap: 5px;
  cursor: pointer; 
`
const logo = css`
  max-height: 80px;
  max-width: 80px;
  margin: 0 auto; 
`

const LoginPage = ({endpoint, pageLayout, formLayout}) => {

  useEffect(() => { 
    document.title = "Login - ShopSharer"
  }, [])

  let navigate = useNavigate()

  const handleClickGoBack = () => {
    navigate(-1)
  }

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleClickPasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleEmailBlur = () => {
    setEmailError(emailValidator(email))
  }

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordError("Please enter your password.")
    }
  }

  useEffect(() => {
    setPasswordError("")
  }, [password])

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    const errors = passwordError || emailError
    console.log("Here are the errors", errors)

    let body = {
      "email": email,
      "password": password
    }

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      mode: "cors",
      credentials: "same-origin"
    }

    if (!errors) {
      try {
        const response = await axios.post(`${endpoint}/users/login`, body, options)
        console.log("response data", response.data)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('username', response.data.username)
        localStorage.setItem('userAvatar', response.data.userAvatar)
        window.location.href = '/';
      } catch (error) {
        console.error("Errors", error);
      }
    }
  }

  return (
    <Box css={pageLayout}>
      <Button 
        onClick={handleClickGoBack} 
        style={{alignSelf: "flex-start"}}
      >
        &lang; Back
      </Button>
      <Logo css={logo} />
      <Typography variant="h1" style={{textAlign: "center"}}>Log In</Typography>
      <Typography style={{textAlign: "center"}}>Discover what the world is buying</Typography>
      <form css={formLayout} onSubmit={handleFormSubmit}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleEmailBlur}
          error={emailError.length > 0}
          helperText={emailError}
        />
        <TextField 
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={handlePasswordBlur}
          error={passwordError.length > 0}
          helperText={passwordError}
          InputProps={{
            endAdornment:
              <InputAdornment onClick={handleClickPasswordVisibility} css={showPasswordStyle} position="end">
                <Typography>{showPassword ? "Hide" : "Show"}</Typography>
                <IconButton>
                  {showPassword ? <EyeOff color="#565656" /> : <Eye color="#565656" />}
                </IconButton>
              </InputAdornment>
          }}
        />
        <Box css={formAction}>
          <Button type="submit" variant='contained' style={{minWidth: "140px"}} disableElevation >Log in</Button>
          <Link href="/forgot-password">
            <Button>Forgot Password?</Button>
          </Link>
        </Box>
      </form>
      <Box style={{margin: "0 auto", textAlign: "center"}}>
        <Typography variant="h6">Don't have an account?</Typography>
        <Link href="/signup" underline="hover">Create an account &#10217;</Link>
      </Box>
    </Box>
  )
}

export default LoginPage