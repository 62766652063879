const avatars = [
  'avatar-dog1.svg',
  'avatar-dog2.svg',
  'avatar-dog3.svg',
  'avatar-dog4.svg',
  'avatar-dog5.svg',
  'avatar-dog6.svg',
  'avatar-dog7.svg',
  'avatar-dog8.svg',
  'avatar-dog9.svg',
  'avatar-dog10.svg',
  'avatar-dog11.svg',
  'avatar-dog12.svg',
  'avatar-dog13.svg',
  'avatar-dog14.svg',
  'avatar-dog15.svg',
  'avatar-dog16.svg',
  'avatar-dog17.svg',
  'avatar-dog18.svg',
  'avatar-dog19.svg',
  'avatar-dog20.svg',
  'avatar-dog21.svg',
  'avatar-dog22.svg',
  'avatar-dog23.svg',
  'avatar-dog24.svg',
  'avatar-dog25.svg',
  'avatar-dog26.svg',
  'avatar-dog27.svg',
  'avatar-dog28.svg',
  'avatar-dog29.svg',
  'avatar-dog30.svg',
  'avatar-cat1.svg',
  'avatar-cat2.svg',
  'avatar-cat3.svg',
  'avatar-cat4.svg',
  'avatar-cat5.svg',
  'avatar-cat6.svg',
  'avatar-cat7.svg',
  'avatar-cat8.svg',
  'avatar-cat9.svg',
  'avatar-cat10.svg',
  'avatar-cat11.svg',
  'avatar-cat12.svg',
  'avatar-cat13.svg',
  'avatar-cat14.svg',
  'avatar-cat15.svg',
  'avatar-cat16.svg',
  'avatar-cat17.svg',
  'avatar-cat18.svg',
  'avatar-cat19.svg',
  'avatar-cat20.svg',
  'avatar-person1.svg',
  'avatar-person2.svg',
  'avatar-person3.svg',
  'avatar-person4.svg',
  'avatar-person5.svg',
  'avatar-person6.svg',
  'avatar-person7.svg',
  'avatar-person8.svg',
  'avatar-person9.svg',
  'avatar-person10.svg',
  'avatar-person11.svg',
  'avatar-person12.svg',
  'avatar-person13.svg',
  'avatar-person14.svg',
  'avatar-person15.svg',
  'avatar-person16.svg',
  'avatar-person17.svg',
  'avatar-person18.svg',
  'avatar-person19.svg',
  'avatar-person20.svg',
  'avatar-person21.svg',
  'avatar-person22.svg',
  'avatar-person23.svg',
  'avatar-person24.svg',
]

export const avatarRandomizer = () => {
  const randomNum = Math.floor(Math.random() * avatars.length)
  return avatars[randomNum]
}