/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { css } from "@emotion/react"
import { Typography, Box, TextField, Button, Snackbar, Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { emailValidator } from '../common/emailUtils'

const textContent = css`
  display:flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;

  @media (min-width: 600px) {
    width: 60%;
  }
  @media (min-width: 900px) {
    width: 40%;
  }
`

const ForgotPasswordPage = ({ endpoint, pageLayout, formLayout }) => {
  const token = localStorage.getItem("token")

  useEffect(() => { 
    document.title = "Forgot Password - ShopSharer"
  }, [])

  let navigate = useNavigate()

  const handleClickGoBack = () => {
    navigate(-1)
  }

  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const handleEmailBlur = () => {
    setEmailError(emailValidator(email))
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      mode: "cors",
      credentials: "same-origin"
    }

    if (!emailError) {
      try {
        const response = await axios.post(`${endpoint}/users/forgot`, {"Email": email}, options)
        console.log("Response data", response.data)
        setOpen(true)
      } catch (error) {
        console.error("Errors", error)
      }
    }

  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
  }

  return (
    <Box css={pageLayout}>
      <Button 
        onClick={handleClickGoBack} 
        style={{alignSelf: "flex-start"}}
      >
        &lang; Back
      </Button>
      <Box css={textContent}>
        <Typography variant="h1">Forgot Your Password?</Typography>
        <Typography>Enter the email address you used to create your account and we will send you an email with a reset password link.</Typography>
      </Box>
      <form onSubmit={handleFormSubmit} css={formLayout}>
        <TextField 
          label="Email Address"
          type='email'
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleEmailBlur}
          error={emailError.length > 0}
          helperText={emailError}
        />
        <Button type="submit" variant="contained" disableElevation>Send Reset Email</Button>
      </form>
      <Snackbar 
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{marginTop: '80px'}}
      >
        <Alert onClose={handleClose} severity="success">Email sent! Please check your email.</Alert>
      </Snackbar>
    </Box>
  )
}

export default ForgotPasswordPage