/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import { AppBar, Typography, IconButton, Menu, MenuItem, Avatar, Tooltip, Toolbar, Box, Button, ListItemText } from '@mui/material'
import { useNavigate, Link } from 'react-router-dom'
import { css } from "@emotion/react"
import { ReactComponent as Logo } from '../assets/shopsharer-logo.svg'
import HideOnScroll from '../common/HideOnScroll'
import { logout } from '../common/logout'

const settings = ['Account', 'Logout']

const logo = css`
  max-height: 45px;
  max-width: 45px;
`

const Header = ({ isLoggedIn, isMobile }) => {
  const username = localStorage.getItem("username")
  const avatar = localStorage.getItem("userAvatar")

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickAccountSettings = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseAccountSettings = () => {
    setAnchorEl(null)
  }

  console.log("username", username)

  const openAccountSettings = Boolean(anchorEl)

  return (
    <HideOnScroll>
      <AppBar>
        <Toolbar css={css`justify-content: space-between;`}>
          <Link to="/">
            <Button startIcon={<Logo css={logo}/>}>
              <Typography css={css`font-family: Nunito; text-transform: capitalize; color: #2D2D2D`}>ShopSharer</Typography>
            </Button>
          </Link>
          <Box>
            {!isMobile && !isLoggedIn && (
              <Box style={{display: "flex", gap: 10}}>
                <Link to="/login">
                  <Button>Login</Button>
                </Link>
                <Link to="/signup">
                  <Button variant="contained" disableElevation>Sign Up</Button>
                </Link>
              </Box>
            )}
            {!isMobile && isLoggedIn && (
              <>
                <Tooltip title="Settings">
                  <Button onClick={handleClickAccountSettings}>
                    <Avatar src={`/avatars/${avatar}`}/>
                    <Typography css={css`margin-left: 15px`}>Hi {username}!</Typography>
                  </Button>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={openAccountSettings}
                  onClose={handleCloseAccountSettings}
                >
                  <MenuItem component={Link} to="/reset-password">
                    <ListItemText> Change Password</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )

}


export default Header