/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from "@emotion/react"
import { Container, Typography, Box, Link } from '@mui/material'

const footer = css`
  width: 100%;
  background-color: #F9F9F9;
  min-height: 50px; 
  padding: 10px 0px;
  margin-top: auto; 
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (min-width: 1280px) {
    text-align: left; 
  }
`

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer css={footer}>
      <Container disableGutters={true} css={css`display: flex; gap: 10px;`}>
        <Typography variant="body2" color="textSecondary">&copy; {currentYear} ShopSharer</Typography>
        <Link href="/privacy-policy" variant='body2' color="textSecondary">Privacy Policy</Link>
      </Container>
    </footer>
  )
}

export default Footer 