export const dateFormatter = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })
}

export const linkValidator = (url) => {
  const inputElement = document.createElement('input')
  inputElement.type = 'url'
  inputElement.value = url

  if (!url) {
    return "Please enter a URL if you would like to make a post"
  }

  if (!inputElement.checkValidity()) {
    console.log("Temp link check", inputElement)
    return "Please enter a valid URL"
  }

  return ""
}