/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { css } from "@emotion/react"
import { Typography, Box, TextField, Button, InputAdornment, Alert, Snackbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Eye, EyeOff } from 'react-ionicons'
import { passwordRuleValidator, checkPasswordsMatch} from '../common/passwordUtils'

const showPasswordStyle = css`
  line-height: 0;
  gap: 5px;
  cursor: pointer; 
`

const ResetPasswordPage = ({ endpoint, pageLayout, formLayout }) => {
  const token = localStorage.getItem("token")

  useEffect(() => { 
    document.title = "Reset Password - ShopSharer"
  }, [])

  let navigate = useNavigate()

  const handleClickGoBack = () => {
    navigate(-1)
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordErrors, setPasswordErrors] = useState([])
  const [confirmPasswordError, setConfirmPasswordErrors] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [openMessage, setOpenMessage] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false)
  }

  const handleSubmitChangePassword = async (event) => {
    event.preventDefault()

    const errors = passwordErrors > 0 || confirmPasswordError

    let body = {
      "Email": email,
      "Password": password
    }

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      mode: "cors",
      credentials: "same-origin"
    }

    if (!errors) {
      try {
        const response = await axios.post(`${endpoint}/users/reset`, body, options)
        setOpenMessage(true)
        console.log("response data", response.data)
      } catch (error) {
        console.error("Errors", error);
      }
    }
  }

  const handlePasswordBlur = () => {
    const errors = passwordRuleValidator(password)

    setPasswordErrors(errors)
  }

  const handleConfirmPasswordBlur = () => {
    setConfirmPasswordErrors(checkPasswordsMatch(password, confirmPassword))
  }

  return (
    <Box css={pageLayout}>
      <Button 
        onClick={handleClickGoBack} 
        style={{alignSelf: "flex-start"}}
      >
        &lang; Back
      </Button>
      <Typography variant="h1" style={{textAlign: "center"}}>Reset Your Password</Typography>
      <form css={formLayout} onSubmit={handleSubmitChangePassword}>
        <TextField 
          type="email"
          value={email}
          autoComplete='email'
          InputProps={{
            style: {display: 'none'},
            'aria-hidden': true
          }}
        />
        <TextField 
          label="New Password"
          value={password}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={handlePasswordBlur}
          autoComplete='new-password'
          error={passwordErrors.length > 0}
          helperText={passwordErrors.map((error) => (
            <React.Fragment key={error}>
              {error}
              <br />
            </React.Fragment>
          ))}
          InputProps={{
            endAdornment:
              <InputAdornment css={showPasswordStyle} onClick={() => setShowPassword(!showPassword)} position="end">
                <Typography>{showPassword ? "Hide" : "Show"}</Typography>
                {showPassword ? <EyeOff color="#565656" /> : <Eye color="#565656" />}
              </InputAdornment>
          }}
        />
        <TextField 
          label="Confirm New Password"
          value={confirmPassword}
          type={showConfirmPassword ? 'text' : 'password'}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={handleConfirmPasswordBlur}
          error={confirmPasswordError.length > 0}
          helperText={confirmPasswordError}
          autoComplete='new-password'
          InputProps={{
            endAdornment:
              <InputAdornment css={showPasswordStyle} onClick={() => setShowConfirmPassword(!showConfirmPassword)} position="end">
                <Typography>{showConfirmPassword ? "Hide" : "Show"}</Typography>
                {showConfirmPassword ? <EyeOff color="#565656" /> : <Eye color="#565656" />}
              </InputAdornment>
          }}
        />
        <Button type="submit" variant="contained" disableElevation> 
          Reset Password
        </Button>
      </form>
      <Snackbar open={openMessage}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{marginTop: '80px'}}
      >
        <Alert 
          onClose={handleClose} 
          severity="success"
          action={
            <Button href="/login" size='small' color="inherit" >Go to Login</Button>
          }
        >
          Your password has been successfully updated!
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ResetPasswordPage