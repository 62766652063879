/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React, {useState, useEffect} from 'react'
import jwt, { jwtDecode } from 'jwt-decode'
import { Card, CardHeader, CardContent, Avatar, Button, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, TextField, Box, Grid, Typography, CardActions, CardMedia, } from '@mui/material'
import { OpenOutline, EllipsisHorizontal, PencilOutline, TrashOutline, AddCircleOutline, Add } from 'react-ionicons'
import { dateFormatter } from "../common/generalUtils"

import ItemSocial from './ItemSocial'
import axios from 'axios'

const cardWrapper = css`
  max-width: 750px;
  margin-bottom: 20px;
`
const card = css`
  @media (min-width: 900px) {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(12, 1fr);
    max-height: 325px;
    align-items: center;
  }
`
const cardTitleAndSeller = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  // margin-bottom: 0.8rem;
`

const cardTitle = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

`

const cardImageWrapper = css`
  @media (min-width: 900px) {
    grid-area: 1 / 1 / 4 / 6;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    // position: relative;
  }
`
const image = css`
  height: 100%;
`

const cardHeaderWrapper = css`
  @media (min-width: 900px) {
    grid-area: 1 / 6 / 2 / 13;
  }
`

const cardContentWrapper = css`
  @media (min-width: 900px) {
    grid-area: 2 / 6 / 3 / 13;
  }
`

const cardActionsWrapper = css`
  @media (min-width: 900px) {
    grid-area: 3 / 6 / 4 / 13; 
  }
`;

const cardActions = css`
  flex-direction: column; 
  padding: 16px; 
  gap: 2rem;
  @media (min-width: 900px) {
    flex-direction: row;
  }
`;


const ItemCard = (props) => {
  // console.log('*** ItemCard props=',props)
  const [followed, setFollowed] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [editing, setEditing] = useState(false)
  const [price, setPrice] = useState(props.price)
  const token = localStorage.getItem("token")
  const decodedToken = jwtDecode(token)
  const userId = decodedToken.userId
  useEffect(() => {
    setFollowed()
  })

  const handleFollow = async () => {
    setFollowed(!followed)

    let body = {
      "userId": userId,
      "followUserId": props.userId,
      "operation": followed ? "unfollow" : "follow" 
    }

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      mode: "cors",
      credentials: "same-origin"
    }

    try {
      const response = await axios.post(`${props.endpoint}/users/follow`, body, options)
      console.log("response data", response.data)
    } catch (error) {
      console.log("Errors:", error)
    }
  }

  const isUserPost = true

  const handleClickPostOptions = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePostOptions = () => {
    setAnchorEl(null)
  }

  const handleEditMode = () => {
    setEditing(!editing)
  }

  const handlePriceChange = (event) => {
    const inputPrice = event.target.value
    const numericPrice = inputPrice.replace(/[^0-9.]/g, '').replace(/(\.\d{0,2})\d*/g, '$1');
    setPrice(numericPrice)
  }

  const handleSavePost = (event) => {
    event.preventDefault();
    setEditing(false)
  }

  const openPostOptions = Boolean(anchorEl)



  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} css={cardWrapper}>
        <Card>
          <Grid container direction={{ xs: 'column', sm: 'row' }}  css={card}>
            <Grid item xs={12} md={5} sx={{ order: { xs: 0, md: -1} }} css={cardImageWrapper}>
              <CardMedia 
                component="img"
                src={props.imageURL}
                alt="Product Preview"
                css={image}
              />
            </Grid>
            <Grid item xs={12} sx={{ order: { xs: -1, md: 0} }} css={cardHeaderWrapper}>
              <CardHeader
                avatar={
                  <Avatar 
                    src={`/avatars/${props.userAvatar}`}
                    css={css`background-color: #fde9f3`}
                  />
                }
                title={props.username}
                subheader={dateFormatter(props.buyDate)}
                action={
                  isUserPost ? <IconButton onClick={handleClickPostOptions}><EllipsisHorizontal color="#565656"/></IconButton> : <Button onClick={handleFollow} variant="outlined" size="small">{followed ? "Following" : "Follow"}</Button>
                }
              />
              <Menu
                anchorEl={anchorEl}
                open={openPostOptions}
                onClose={handleClosePostOptions}
              >
               <MenuItem  onClick={handleEditMode}>
                <ListItemIcon style={{lineHeight: 0}}><PencilOutline /></ListItemIcon>
                <ListItemText>Edit Post</ListItemText>
               </MenuItem>
               <MenuItem>
                <ListItemIcon style={{lineHeight: 0}}><TrashOutline /></ListItemIcon>
                <ListItemText>Delete Post</ListItemText>
               </MenuItem> 
              </Menu>
            </Grid>
           <Grid item xs={12} css={cardContentWrapper}>
              <CardContent>
                <Box css={cardTitleAndSeller}>
                  <Typography css={cardTitle} variant="h2">{props.title}</Typography>
                  <Typography variant="h4">{props.seller}</Typography>
                </Box>
              
                {editing ? 
                  <form  onSubmit={handleSavePost} style={{display: "flex", alignItems: "center"}}>  
                    <TextField
                      inputProps={{inputMode: 'decimal', pattern: '[0-9]*[.]?[0-9]*', }}
                      label="Price"
                      placeholder="ex. 210.00"
                      value={price}
                      onChange={handlePriceChange}
                      size="small"
                    /> 
                    <Button type="submit">
                      Save
                    </Button>
                  </form>
                  : (
                  <Box style={{height: 40, display: "flex", alignItems: "center"}}>
                    {price === 0 ?  
                      <Button startIcon={<AddCircleOutline color="primary" />} onClick={handleEditMode}>Add Price</Button> 
                    : (<Typography>$ {parseFloat(price).toFixed(2)}</Typography>)}
                  </Box>
                )}
              </CardContent>
            </Grid>
            <Grid item xs={12} css={cardActionsWrapper}>
              <CardActions css={cardActions}>
                <Button 
                  href={props.buyURL}
                  target="blank"
                  variant="contained"
                  rel="noopener noreferrer"
                  disableElevation endIcon={<OpenOutline height="20px" color={'#00000'}/>} 
                  css={css`align-items: center; font-size: 1.2rem`} 
                  fullWidth={true} >
                    Buy
                </Button>
                <ItemSocial endpoint={props.endpoint} title={props.title} description={props.description} reactions={props.reactions} url={props.buyURL} buyId={props._id} />
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ItemCard