/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { css } from "@emotion/react"
import { Box, Typography, TextField, Button, InputAdornment, LinearProgress, Alert } from '@mui/material'
import { linkValidator } from '../common/generalUtils'

const goButton = css`
  flex: 0 0 auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  max-height: 56px;
`

const form = css`
  display: flex;
  align-items: stretch;
  width: 100%;
  & > :first-of-type {
    margin-right: -0.2rem;
  }
`

const ItemInput = ({ addNewItem, endpoint }) => {
  const token = localStorage.getItem("token")

  const [itemURL, setItemURL] = useState('')
  const [isPosting, setIsPosting] = useState(false)
  const [linkError, setLinkError] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10))
    }, 10000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleItemSubmit = async (event) => {
    event.preventDefault()
    setLinkError(linkValidator(itemURL))
    setIsPosting(true)
    setProgress(0)

    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10))
    }, 10000)
    
    let body = {
      "URL": itemURL,
      // "UserId": "647e2404aff0bec965005ad3",
    }

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      mode: "cors",
      credentials: "same-origin"
    }
    
    if (!linkError) {
      try {
        console.log("itemURL", itemURL)
        const response = await axios.post(`${endpoint}/users/buy/create`, body, options)
        console.log("Response data for create buy", response.data)
        addNewItem(response.data.data)
        clearInterval(timer)
        setProgress(100)
        setItemURL('')
        setTimeout(() => {
          setProgress(0)
        }, 1000)
      } catch (error) {
        console.error("Errors", error)
        setProgress(0)
      }
      setIsPosting(false)
    }

  }

  return (
    <Box css={css`display: flex; gap: 10px; flex-direction: column; margin-bottom: 20px;`}>
      <Typography variant='h1'>What did you buy today?</Typography>
      <Typography>Paste the link of what you just bought below and ShopSharer will do the rest!</Typography>
      <form onSubmit={handleItemSubmit} css={form}>
        <TextField 
          placeholder="URL e.g. www.store.ca/product-name"
          variant="outlined"
          fullWidth={true}
          value={itemURL}
          onChange={(e) => setItemURL(e.target.value)}
          onBlur={() => setLinkError(linkValidator(itemURL))}
          error={linkError.length > 0}
          helperText={linkError}
          disabled={isPosting}
        />
        <Button type="submit" variant='contained' disableElevation css={goButton}>Go</Button>
      </form>
      {isPosting && (
        <Box css={css`margin-top: 10px;`}>
          <Box css={css`display:flex; align-items: center;`}>
            <LinearProgress variant="determinate" value={progress} css={css`min-width: 100px; width: 100%; height: 7px;`} />
            <Typography css={css`min-width: 39px; margin-left: 10px;`}>{`${progress}%`}</Typography>
          </Box>
          <Typography>Hang tight! We're generating your post.</Typography>
        </Box>
      )}
      {/* <Alert severity="info">We currently don't support the website you just entered. Please try again later as we're working on expanding our services. Thank you for your patience.</Alert> */}
    </Box>
  )
}

export default ItemInput