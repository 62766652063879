/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { css } from "@emotion/react"
import { List, ListItem, ListItemText, Box, Button, useTheme, Drawer, useMediaQuery, Typography, ListSubheader, IconButton, CircularProgress, Alert } from '@mui/material'
import { Filter, Close, Checkmark, } from 'react-ionicons'
import ItemCard from './ItemCard'

const peopleCategories = ['Community', 'Following']

const productCategories = ['Fashion', 'Beauty', 'Home', 'Electronics', 'Books', 'Health', 'Accessories', 'Toys & Games']

const filterButton = css`
  border-radius: 25px;
`
const generalSpacing = css`
  margin: 10px 16px;
`
const header = css`
  display: flex; 
  justify-content: space-between;
  margin: 10px 16px;
  align-items: center;
`

const listStyle = css`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 600px) {
    max-width: 300px;
  }
`

const spinner = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ItemFeed = ({ feedData, isLoading, timeoutError, error, endpoint }) => {
  console.log("FeedData", feedData)
  const token = localStorage.getItem("token")
  const [openMenu, setOpenMenu] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // const [selected, setSelected] = useState(false)
  const [selectedPeopleFilters, setSelectedPeopleFilters] = useState([])
  const [selectedCategoriesFilters, setSelectedCategoriesFilters] = useState([])
  const [hovered, setHovered] = useState(false)

  
  const toggleFilterMenu = () => {
    setOpenMenu(!openMenu)
  }

  const drawerAnchor = isMobile ? 'bottom' : 'left';

  const handlePeopleFilter = (filter) => {
    if (selectedPeopleFilters.includes(filter)) {
      setSelectedPeopleFilters(selectedPeopleFilters.filter((item) => item !== filter))
    } else {
      setSelectedPeopleFilters([...selectedPeopleFilters, filter])
    }
    console.log(selectedPeopleFilters)
  }

  const handleProductCategoriesFilter = (filter) => {
    if (selectedCategoriesFilters.includes(filter)) {
      setSelectedCategoriesFilters(selectedCategoriesFilters.filter((item) => item !== filter))
    } else {
      setSelectedCategoriesFilters([...selectedCategoriesFilters, filter])
    }
    console.log(selectedCategoriesFilters)
  }

  const handleClearFilters = () => {
    setSelectedCategoriesFilters([])
    setSelectedPeopleFilters([])
  }

  const handleApplyFilters = async () => {
    let selectedFilters = [...selectedPeopleFilters, ...selectedCategoriesFilters]

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "filters": selectedFilters
      },
      mode: "cors",
      credentials: "same-origin"
    }

    try {
      const response = await axios.post(`${endpoint}/users/like`, {}, options)
      console.log("response data", response.data)
    } catch (error) {
      console.error("Errors", error)
    }
  }

  return (
    <>
      <Button startIcon={<Filter />} onClick={toggleFilterMenu}>
        Filter
      </Button>
      <Drawer 
        anchor={drawerAnchor}
        open={openMenu}
        onClose={toggleFilterMenu}
      >
        <Box css={header}>
          <Typography variant="h4">Filter</Typography>
          <IconButton onClick= {toggleFilterMenu}>
            <Close />
          </IconButton>
        </Box>
        <List css={listStyle}>
          <ListSubheader style={{width: '100%'}}>People</ListSubheader>
          {peopleCategories.map((option) => (
              <ListItem 
                key={option} 
                onClick={() => handlePeopleFilter(option)}
                sx={{width: 'auto'}}
              >
                <Button 
                  variant="outlined"
                  fullWidth={false}
                  css={filterButton}
                  size="small"
                  startIcon={selectedPeopleFilters.includes(option) ? <Checkmark /> : null}
                >
                  <ListItemText primary={option} />
                </Button>
              </ListItem>
            ))}
        </List>
        <List css={listStyle}>
          <ListSubheader style={{width: '100%'}}>Categories</ListSubheader>
          {productCategories.map((option) => (
            <ListItem 
              key={option} 
              onClick={() => handleProductCategoriesFilter(option)}
              sx={{width: 'auto'}}
            >
              <Button variant="outlined" fullWidth={false} css={filterButton} size="small" startIcon={selectedCategoriesFilters.includes(option) ? <Checkmark /> : null}>
                <ListItemText primary={option} />
              </Button>
            </ListItem>
            ))}
        </List>
        <Button variant="outlined" css={generalSpacing} onClick={handleClearFilters}>
          Clear Filters
        </Button>
        <Button variant='contained' css={generalSpacing} onClick={handleApplyFilters} disableElevation>
          Apply Filters
        </Button>
      </Drawer>
      <Box>
        {selectedPeopleFilters.length > 0 && 
          <List css={css`display: flex; flex-wrap: wrap;`}>
            <ListSubheader style={{width: '100%'}}>People Filters</ListSubheader>
            {selectedPeopleFilters.map((option) => (
              <ListItem 
              key={option} 
              onClick={() => handlePeopleFilter(option)}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              sx={{width: 'auto'}}
            >
              <Button 
                variant="outlined"
                fullWidth={false}
                css={filterButton}
                size="small"
                startIcon={
                  selectedCategoriesFilters.includes(option) ? (
                    hovered ? (
                      <Close />
                    ) : (
                      <Checkmark />
                    )
                  ) : null
                }
              >
                <ListItemText primary={option} />
              </Button>
            </ListItem>
            ))}
          </List>
        }
        {selectedCategoriesFilters.length > 0 && 
          <List css={css`display: flex; flex-wrap: wrap;`}>
            <ListSubheader style={{width: '100%'}}>Product Categories Filters</ListSubheader>
              {selectedCategoriesFilters.map((option) => (
              <ListItem 
                key={option} 
                onClick={() => handleProductCategoriesFilter(option)}
                sx={{width: 'auto'}}
              >
              <Button 
                variant="outlined"
                fullWidth={false}
                css={filterButton}
                size="small"
                startIcon={selectedCategoriesFilters.includes(option) ? <Checkmark /> : null}
              >
                <ListItemText primary={option} />
              </Button>
            </ListItem>
            ))}
          </List>
        }
      </Box>
      <List>
        {/* <Alert severity="error">Request timed out. Please try again later.</Alert> */}
        {isLoading ? (
          <ListItem css={spinner}>
            <CircularProgress />
          </ListItem>
        ) : (
          timeoutError ? (
            <Alert severity="error">{error}</Alert>
          ) : 
          // feedData.length > 0 && 
          feedData.map((item) => (
            <ListItem key={item._id}>
              {console.log(item)}
              <ItemCard {...item} endpoint={endpoint} />
            </ListItem>
          ))
        )}
      </List>
    </>
  )
}

export default ItemFeed