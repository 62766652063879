/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import jwt, { jwtDecode } from 'jwt-decode'
import {TextField, Grid, Typography, Box, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText, Modal } from '@mui/material'
import React, {useState, useEffect} from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { HeartOutline, Heart, ShareSocialOutline, ChatbubblesOutline, BookmarkOutline, Link, MailOutline, InformationCircleOutline, Close } from 'react-ionicons'
import axios from "axios"

const modalStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;

`

const wrapper = css`
  display: flex; 
  flex-direction row; 
  justify-content: flex-end; 
  width: 100%; 
  align-items: center;
`
const socialButtons = css`
  // justify-content: flex-end;
`

const postData = css`
  @media (min-width: 900px) {
    display: none;
  }
`

const ItemSocial = ({ endpoint, buyId, title, description, reactions, url}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [liked, setLiked] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  
  const token = localStorage.getItem("token")
  const decodedToken = jwtDecode(token)
  // console.log("decoded Token", decodedToken)
  const userId = decodedToken.userId
  const likeToolTip = liked ? "Unlike" : "Like"

  useEffect(() => {
    setLiked(reactions.likes.userIds.includes(userId))
  }, [reactions.likes.userIds, userId])

  const handleLike = async (event) => {
    event.preventDefault()

    let body = {
      "userId": userId,
      "buyId": buyId,
      "operation": liked ? "decrement" : "increment"
    }

    console.log("This is the body",body)

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      mode: "cors",
      credentials: "same-origin"
    }

    try {
      const response = await axios.post(`${endpoint}/buys/react`, body, options)
      console.log("response data", response.data)
    } catch (error) {
      console.log("Errors:", error)
    }

    setLiked(!liked)
  }

  const openShare = Boolean(anchorEl)

  useEffect(() => {
  }, []);

  const formatReactionCount = (likes) => {
    if (likes >= 1000) {
      return Math.floor(likes / 1000) + "k+"
    }
    return likes
  }

  return (
    <>
      <Box css={wrapper}>
        {/* <Typography css={postData}>10 Likes</Typography> */}
        <Box css={socialButtons}>
          {/* <IconButton>
            <ChatbubblesOutline />
          </IconButton> */}
          {/* <IconButton>
            <BookmarkOutline />
          </IconButton> */}
          <Tooltip title="More Info" placement="top" arrow>
            <IconButton onClick={() => setOpenInfo(true)}>
              <InformationCircleOutline width="28px" height="28px" color="#565656"/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Share" placement="top" arrow>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <ShareSocialOutline width="28px" height="28px" color="#565656"/>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={openShare}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={()=>navigator.clipboard.writeText(url)}>
              <ListItemIcon style={{lineHeight: 0}}>
                <Link />
              </ListItemIcon>
              <ListItemText>Copy Link</ListItemText>
            </MenuItem>
            <MenuItem>
              <ListItemIcon style={{lineHeight: 0}}>
                <MailOutline />
              </ListItemIcon>
              <ListItemText>Email</ListItemText>
            </MenuItem>
          </Menu>
          <Tooltip title={likeToolTip} placement="top" arrow>
            <IconButton
              onClick={handleLike}
            >
              {liked ? <Heart width="28px" height="28px" color="#f2251a" /> : <HeartOutline width="28px" height="28px" color="#565656"/>}
              <Typography css={css`margin-left: 5px`}>{formatReactionCount(reactions.likes.count)}</Typography>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Modal open={openInfo} onClose={() => setOpenInfo(false)} >
        <Box css={modalStyles}>
          <Box css={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '15px'}}>
            <Typography variant="h4">{title}</Typography>
            <IconButton onClick={() => setOpenInfo(false)}>
              <Close />
            </IconButton>
          </Box>
          {/* <Typography>{title}</Typography> */}
          <Typography variant='h5'>Description</Typography>
          <Typography>{description}</Typography>
        </Box>
      </Modal>
    </>
  )
}

export default ItemSocial