export const emailValidator = (email) => {

  if (!email) {
    return "Please enter your email"
  }
  
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
    return "Please enter a valid email."
  } else {
    return ""
  }
}