/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { css } from "@emotion/react"
import { Typography, Box, Link } from '@mui/material'

const PrivacyPolicyPage = ({ pageLayout }) => {

  useEffect(() => { 
    document.title = "Privacy Policy - ShopSharer"
  }, [])

  return (
    <Box css={[pageLayout, css`display:flex; flex-direction: column; gap: 20px; max-width: 1200px;`]}>
      <Typography variant="h1">Privacy Policy</Typography>
      <section>
        <Typography>Last updated: June 26th, 2023</Typography>
        <Typography>This Privacy Policy governs the manner in which ShopSharer ("we," "our," or "us") collects, uses, maintains, and discloses information collected from users (each, a "User") of the shopsharer.com website (the "Website"). This Privacy Policy applies to the Website and all products and services offered by ShopSharer.</Typography>
      </section>
      <section>
        <Typography variant="h2">1. Personal Identification Information</Typography>
        <Typography>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our Website, fill out a form, and in connection with other activities, services, features, or resources we make available on our Website. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Website anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Website-related activities.</Typography>
      </section>
      <section>
        <Typography variant="h2">2. Non-personal Identification Information</Typography>
        <Typography>We may collect non-personal identification information about Users whenever they interact with our Website. Non-personal identification information may include the browser name, the type of computer, and technical information about Users' means of connection to our Website, such as the operating system and the Internet service providers utilized and other similar information.</Typography>
      </section>
      <section>
        <Typography variant="h2">3. Web Browser Cookies</Typography>
        <Typography>Our Website may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent. If they do so, note that some parts of the Website may not function properly.</Typography>
      </section>
      <section>
        <Typography variant="h2">4. How We Use Collected Information</Typography>
        <Typography>
          <ul>
            <li>To improve customer service: Information you provide helps us respond to your customer service requests and support needs more efficiently.</li>
            <li>To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Website.</li>
            <li>To improve our Website: We continually strive to improve our Website offerings based on the information and feedback we receive from you.</li>
            <li>To send periodic emails: We may use the email address to respond to inquiries, questions, and/or other requests from Users.</li>
          </ul>
        </Typography>
      </section>
      <section>
        <Typography variant="h2">5. How We Protect Your Information</Typography>
        <Typography>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Website.</Typography>
      </section>
      <section>
        <Typography variant="h2">6. Sharing Your Personal Information</Typography>
        <Typography>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers.</Typography>
      </section>
      <section>
        <Typography variant="h2">7. Third-Party Websites</Typography>
        <Typography>Users may find advertising or other content on our Website that link to the websites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these websites and are not responsible for the practices employed by websites linked to or from our Website. In addition, these websites or services, including their content and links, may be constantly changing. These websites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Website, is subject to that website's own terms and policies.</Typography>
      </section>
      <section>
        <Typography variant="h2">8. Changes to This Privacy Policy</Typography>
        <Typography>We have the discretion to update this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.</Typography>
      </section>
      <section>
        <Typography variant="h2">9. Your Acceptance of These Terms</Typography>
        <Typography>By using this Website, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our Website. Your continued use of the Website following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.</Typography>
      </section>
      <section>
        <Typography variant="h2">10. Contacting Us</Typography>
        <Typography>If you have any questions about this Privacy Policy, the practices of this Website, or your dealings with this Website, please contact us at <Link href="mailto:info@shopsharer.com">info@shopsharer.com</Link>.</Typography>
      </section>
      <Typography>This Privacy Policy was last updated on Jun 26th, 2023.</Typography>
    </Box>
  )
}

export default PrivacyPolicyPage