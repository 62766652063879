/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import { css } from "@emotion/react"
import { BottomNavigation, Typography, Box, BottomNavigationAction, Button } from '@mui/material'
import { Home, Person, AddCircle, LogOut } from 'react-ionicons'
import { Link } from 'react-router-dom'
import { logout } from '../common/logout'

const bottomNav = css`
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`
const loggedOutState = css`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
`

const BottomNav = ({ isLoggedIn }) => {
  const token = localStorage.getItem("token")

  const [value, setValue] = useState(0)

  return (
   <>
    {!isLoggedIn ? (
      <BottomNavigation>
        <Box css={loggedOutState}>
          <Link to="/login">
            <Button>Login</Button>
          </Link>
          <Link to="/signup">
           <Button variant="contained" disableElevation>Sign Up</Button>
          </Link>
        </Box>
      </BottomNavigation>
      ): (
        <BottomNavigation
         showLabels
         css={bottomNav}
         value={value}
         onChange={(event, newValue) => {
           setValue(newValue);
         }}
       >
          <BottomNavigationAction label="Home" icon={<Home />} />
          <BottomNavigationAction label="New Post" icon={<AddCircle />} />
          {/* <BottomNavigationAction label="Account" icon={<Person />} /> */}
          <BottomNavigationAction label="Logout" icon={<LogOut />} onClick={logout}/>
        </BottomNavigation>
      )}
   </>
  )
}

export default BottomNav