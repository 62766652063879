import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E0157A'
    }, 
    secondary: {
      main: '#860C49'
    },
    background: {
      main: '#F9F9F9'
    },
    text: {
      primary: '#2D2D2D'
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: '2.75rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.125rem',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        endIcon: {
          lineHeight: '0rem',
        },
        startIcon: {
          lineHeight: '0rem'
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#565656",
          fontSize: 0,
          
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          margin: 0,
          alignSelf: 'center'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 8, 
          ":last-child": {
            paddingBottom: 8
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
         backgroundColor: "white",
         boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
         paddingTop: 5,
         paddingBottom: 5

        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          }
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
        },
        root: {
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          objectFit: "contain",
          width: "80%",
          height: "80%"
        }
      }
    }
  },
})

export default theme