
export const checkPasswordsMatch = (password, confirmPassword) => {

  if (!confirmPassword) {
    return "Please confirm your password"
  }
  
  if (password !== confirmPassword) {
    return "Passwords do not match"
  } else {
    return ""
  }
}

const passwordRules = [
  {
    "rule": (password) => password, 
    "message": "Please enter a password"
  },
  {
    "rule": (password) => /[A-Z]/.test(password), 
    "message": "Password must contain at least 1 uppercase letter"
  },
  {
    "rule": (password) => /[!@#$%^&*]/.test(password), 
    "message": "Password must contain at least one special character"
  },
  {
    "rule": (password) => /\d/.test(password), 
    "message": "Password must contain at least one number"
  },
  {
    "rule": (password) => password.length > 8, 
    "message": "Password must be at least 8 characters long"
  },

]

export const passwordRuleValidator = (password) => {

  const errors =[]

  passwordRules.forEach((rule) => {
    if (!rule.rule(password)) {
      errors.push(rule.message)
    }
  })

  return errors
}