/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import './App.css'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {TextField, Grid, Button, useTheme, useMediaQuery } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { ThemeProvider } from "@mui/material/styles"
import theme from './theme'
import endpoints from "./common/endpoints";

import Header from './components/Header'
import HomePage from "./components/HomePage"
import LoginPage from "./components/LoginPage"

import BottomNav from "./components/BottomNav"
import Footer from "./components/Footer"
import SignUpPage from "./components/SignUpPage";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import ResetPasswordPage from "./components/ResetPasswordPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import { jwtDecode } from "jwt-decode";

const pageContent = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  margin: 0 1rem 70px;
  padding: 1rem 0;

  @media (min-width: 600px) {
    margin: 0 1.5rem 60px;
  }
  @media (min-width: 960px) {
    margin: 0 2rem;
  }
  @media (min-width: 1280px) {
    margin: 0 5rem;
  }
  @media (min-width: 1440px) {
    margin: 0 8rem;
  }
  @media (min-width: 1920px) {
    margin: 0 20rem;
  }
`

const pageLayout = css`
  display:flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  max-width: 1200px;
`
const formLayout = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 900px) {
    width: 40%;
  }
`
function App() {
  const token = localStorage.getItem("token")
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [endpoint, setEndpoint] = useState('')

  useEffect(() => {

    const currentTime = new Date().getTime()
    console.log("This is the current time",currentTime)

    if (token) {
      const decodedToken = jwtDecode(token)

      if (decodedToken.exp * 1000 > currentTime) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('userAvatar')
      }
    } else {
      setIsLoggedIn(false)
    }
    
  }, [token])

  useEffect(() => {
    const hostname = window.location.hostname
   
    console.log('*** hostname=',hostname)
    console.log('*** endpoints[hostname]=',endpoints[hostname])

    const endpointValue = endpoints[hostname] || ''

    setEndpoint(endpointValue)
  },[])

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Grid container direction="column" css={css`min-height: 100vh`}>
          <Grid item css={{marginBottom: '85px'}}>
            <Header isLoggedIn={isLoggedIn} isMobile={isMobile} />
          </Grid>
          <Grid item css={pageContent}>
            <Routes>
              <Route path="/" element={<HomePage endpoint={endpoint} />} />
              <Route path="/login" element={<LoginPage endpoint={endpoint} pageLayout={pageLayout} formLayout={formLayout}/>} />
              <Route path="/signup" element={<SignUpPage endpoint={endpoint} pageLayout={pageLayout} formLayout={formLayout}/>} />
              <Route path="/forgot-password" element={<ForgotPasswordPage endpoint={endpoint} pageLayout={pageLayout} formLayout={formLayout}/>} />
              <Route path="/reset-password" element={<ResetPasswordPage endpoint={endpoint} pageLayout={pageLayout} formLayout={formLayout} />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage endpoint={endpoint} />} pageLayout={pageLayout} />
            </Routes>
          </Grid>
          <Grid item>
            {isMobile ? <BottomNav isLoggedIn={isLoggedIn} /> : <Footer />}
          </Grid>
        </Grid>
      </Router>
    </ThemeProvider>
  );
}

export default App
