/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import {TextField, Grid, getCardMediaUtilityClass, Typography, Box, useMediaQuery, useTheme } from '@mui/material'
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import ItemInput from "./ItemInput"
import ItemFeed from "./ItemFeed"

const HomePage = ({ endpoint }) => {
  const token = localStorage.getItem("token")
  
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // const [cardData, setCardData] = useState(null)
  // const [itemURL, setItemURL] = ''
  const [feedData, setFeedData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [timeoutError, setTimeoutError] = useState(false)
  const timeoutDuration = 10000

  useEffect(() => {
    if (endpoint) {
      getFeedData()
    }
  }, [endpoint])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const addNewItem = (newItem) => {
    setFeedData((prevFeedData) => (prevFeedData ? [newItem, ...prevFeedData] : [newItem]))
  }

  const getFeedData = async () => {
    setIsLoading(true)

    let body = {
      "categories": ["fashion", "electronics"],
      "buyCount": 0
    }

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      mode: "cors",
      credentials: "same-origin",
      timeout: 45000 
    }

    try {
      const response = await axios.post(`${endpoint}/users/find`, body, options)
      console.log("response", response)
      setFeedData(response.data.data[0].feed)
      setIsLoading(false)
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        setTimeoutError(true)
        console.log('Request timed out')
      } else {
        setError("There was an error processing your request. Please try again later.")
        console.log("Errors:", error)
      }
      setIsLoading(false)
    }
  }

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement
    if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoading) {
      getFeedData() // Need to track number of posts
    }
  } 
  // const useHandleURL = (event) => {
  //   const test = async (event) => {
  //     console.log('*** url=',event.target.value)
  //     const getCardData = async () => {
  //       let response = await axios.post(`${endpoint}/users/buy/create`,{},{"headers":{"url": event.target.value}})
  //       console.log('*** back from axios')
  //       setCardData(response.data.message)
  //       console.log('response.data.message=',response.data.message)
  //     }
  //     console.log('before cardData=',cardData)
  //     if (!cardData) {getCardData(event)}
  //   }
  //   test(event)
  // }

  const handleSubmit = () => {
  }

  const handleComplaint = () => {
  }

  useEffect(() => { 
    document.title = "Home - ShopSharer"
  }, [])

  return (
    <Grid container alignItems="flex-start" justifyContent="center" spacing={2} css={css`max-width: 1200px`}>
      <Grid item xs={12} sm={10} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemInput addNewItem={addNewItem} endpoint={endpoint} />
          </Grid>
          <Grid item xs={12}>
              <ItemFeed feedData={feedData} setFeedData={setFeedData} isLoading={isLoading} error={error} timeoutError={timeoutError} endpoint={endpoint} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item >
            {/* <Typography css={{border: 'grey 1px dotted', width: 300, height: 250}}>Google Ad</Typography> */}
          </Grid>
          <Grid item >
            {/* <Typography css={{border: 'grey 1px dotted', width: 300, height: 250}}>Google Ad</Typography> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
      // </Grid>
      /* <header className="App-header">
        <Grid css={css`height: 95%;`} container spacing={2}>
          <Grid css={css`height: 95%;`} item xs={12} sm={8}>
            <TextField 
              id="outlined-basic" 
              InputLabelProps={{shrink: false}}
              css={css`margin-top:2%; width: 80%; border-color: grey`} 
              label="Cut &#38; paste the link / URL of what you purchased." 
              value={itemURL}
              variant="outlined" 
              onChange={useHandleURL}
            />
          </Grid>
        </Grid>
      </header> */
    /* </Grid > */
  )
}

export default HomePage